.resident-list-view {
    margin: 20px;
    font-family: Quicksand, sans-serif;
}


.create-new-resident {
    display: inline-block;
    margin-bottom: 20px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.residents-table {
    width: 100%;
    border-collapse: collapse;
}

.residents-table th, .residents-table td {
    border: 1px solid var(--dark-navy-color);
    padding: 8px;
    text-align: left;
}

.residents-table tr:nth-child(even){background-color: var(--off-white-color);}

.residents-table tr:hover {background-color: var(--accent-color);}

.residents-table th {
    background-color: var(--off-white-color);
    cursor: pointer;
    position: relative;
    padding-right: 25px;
}

.residents-table th:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}


.view-details, .edit, .delete {
    margin-right: 10px;
    text-decoration: none;
    color: var(--off-white-color);
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--accent-color-2);
}

.timeslot-cell {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Space between each entry if multiple */
}
  
 
  .timeslot_preferred { background-color: white; border: 1px solid black; }
  .timeslot_required, .start_time { background-color: red; color: green; }
  .workers-count { background-color: yellow; color: black; width: 20px; height: 20px; border-radius: 50%; display: inline-block; text-align: center; line-height: 20px; }

  .current-details {
    display: flex;
    gap: 10px; 
}

.current-container {
    display: flex;
    gap: 10px;
}

.indicator-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 4px;
}

.circle, .square {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
    border-radius: 50%;
}

.red {
    background-color: red;
}

.pink {
    background-color: var(--accent-color);
    border: 1px solid white;
}

.white {
    background-color: white;
    border: 1px solid black;
}

.square {
    background-color: yellow;
}

.details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px; /* Adjust the gap between time and duration */
}

.time-detail, .duration-detail {
    color: navy;
}

.timeslot-column {
    width: 15%;
    text-align: center; 
}

.timeslot-time {
  font-size: smaller;
  font-weight: normal;
  margin-left: 8px;
  white-space: nowrap;
}

.time-text { 
    background-color: red;
    color: rgb(230, 175, 175);  
    margin-right: 5px;    
}

