.rbc-calendar {
    font-family: 'Quicksand', sans-serif;
    color: var(--text-color);
    background-color: var(--off-white-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
}

.rbc-toolbar {
    font-family: 'Quicksand', sans-serif;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 0.5rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.rbc-toolbar button {
    font-family: 'Quicksand', sans-serif;
    background-color: var(--accent-color-2);
    color: var(--off-white-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
}

.rbc-toolbar button:hover {
    background-color: var(--accent-color);
}

.rbc-btn-group button {
    background-color: transparent;
    color: var(--off-white-color);
    margin: 0;
    padding: 0.25rem 0.5rem;
}

.rbc-btn-group button:hover {
    background-color: var(--accent-color-2);
    color: var(--off-white-color);
}

.rbc-off-range-bg {
    background-color: lightgrey;
}

.rbc-event {
    background-color: var(--accent-color-2);
    border: none;
    padding: 2px 5px;
    border-radius: 4px;
    color: var(--off-white-color);
    font-size: 0.85em;
}

.rbc-event:hover {
    background-color: var(--accent-color);
    color: var(--dark-navy-color)
}

.rbc-today {
    background-color: var(--accent-color);
    color: var(--off-white-color);
}

.rbc-header {
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 0.5rem;
    font-weight: bold;
}

.rbc-row-segment .rbc-event {
    cursor: pointer;
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
    .rbc-toolbar button {
        padding: 0.25rem 0.5rem;
    }

    .rbc-btn-group button {
        padding: 0.25rem;
    }
}

.rota-calendar-container .rbc-toolbar {
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
}