.current-list-view {
    margin: 20px;
    font-family: 'Quicksand', sans-serif;
}

.currents-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    table-layout: auto; 
    overflow-x: auto;
}

.currents-table th, .currents-table td {
    border: 1px solid var(--dark-navy-color);
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.currents-table th {
    background-color: var(--off-white-color);
    cursor: pointer;
    position: relative;
    padding-right: 40px;
}

.currents-table th:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.currents-table th.actions-column,
.currents-table td.actions-column {
    min-width: 300px; 
    width: 300px; 
}

.currents-table tr:nth-child(even){background-color: var(--off-white-color);}

.currents-table tr:hover {background-color: var(--accent-color);}

.create-new-current {
    display: inline-block;
    margin-bottom: 20px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}
