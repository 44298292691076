.navbar {
  background-color: transparent; 
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.navbar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0; 
  padding: 0;
}

.navbar li {
  margin: 0 10px;
}

.navbar li a {
  color: var(--text-color);
  padding: 10px 15px;
  text-decoration: none;
}

.navbar li a:hover {
  color: var(--text-color-2);
}

@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
  }

  .navbar li {
    padding: 0.5rem 0; 
  }
} 

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--off-white-color);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  font-size: 16px;
}

.resource-dropdown { /* New class for Resource dropdown specifics */
  cursor: pointer;
  position: relative; /* Important for positioning the dropdown content */
}

.resource-dropdown .dropdown-content {
  display: none; /* Hidden by default */
  position: absolute; /* Aligns the dropdown menu */
  left: 0; /* Align to the left side */
  min-width: 120px; /* Ensure minimum width to accommodate text */
  z-index: 1;
}

.resource-dropdown:hover .dropdown-content {
  display: block; /* Show dropdown content on hover */
}

.resource-dropdown .dropdown-content a {
  color: var(--text-color); /* Ensure text color is set */
  padding: 12px 16px; /* Adjust padding to ensure text fits comfortably */
  text-decoration: none;
  display: block; /* Ensure each link takes up full width of dropdown */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Adjust the hover state to improve visibility */
.resource-dropdown .dropdown-content a:hover {
  background-color: var(--accent-color); /* Light background on hover for better readability */
}