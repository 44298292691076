:root {
  --dark-navy-color: #141B3B;  
  --off-white-color: #FEFFFF; 
  --accent-color: #F7D8F1;
  --accent-color-2: #7774FF;    
  --text-color: #141B3B;
  --font-size-base: 16px;      
}

body {
  font-family: 'Quicksand', sans-serif;
  color: var(--text-color);
  margin: 0;
  background-color: var(--off-white-color);
  font-size: var(--font-size-base);
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--off-white-color);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.App-logo {
  height: 30px;
}

.settings-and-user {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon {
  cursor: pointer;
  color: var(--dark-navy-color);
  font-size: 30px; /* Adjusted to match logo height */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.settings-icon {
  cursor: pointer;
  color: lightslategray; 
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px; 
  text-decoration: none; 
}


.logo-and-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--off-white-color);
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1000;
  font-size: 16px;
}

.user-icon:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  padding: 10px 20px;
  color: var(--text-color);
  display: block;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: var(--accent-color);
  color: var(--dark-navy-color);
}