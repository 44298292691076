.appointment-list-view {
    margin: 20px;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
}

.appointment-list-view .export-button {
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
    margin-right: 5px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    border-color: transparent;
}

.appointment-list-view .generate-button {
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
    margin-right: 5px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    border-color: transparent;
}

.appointment-list-view .clear-button {
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
    margin-right: 5px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    border-color: transparent;
}

.appointments-table {
    width: 100%;
    border-collapse: collapse;
}

.appointments-table th, .appointments-table td {
    border: 1px solid var(--dark-navy-color);
    padding: 8px;
    text-align: left;
}

.appointments-table th {
    background-color: var(--off-white-color);
}

.appointments-table tr:nth-child(even){background-color: var(--off-white-color);}

.appointments-table tr:hover {background-color: var(--accent-color);}

.appointments-table th {
    background-color: var(--off-white-color);
    cursor: pointer;
    position: relative;
    padding-right: 25px;
}

.appointments-table th:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.last-generated {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: lightgray; 
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); 
}