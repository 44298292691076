.worker-list-view {
    margin: 20px;
    font-family: Quicksand, sans-serif;
}


.create-new-worker {
    display: inline-block;
    margin-bottom: 20px;
    background-color: var(--dark-navy-color);
    color: var(--off-white-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}

.workers-table {
    width: 100%;
    border-collapse: collapse;
}

.workers-table th, .workers-table td {
    border: 1px solid var(--dark-navy-color);
    padding: 8px;
    text-align: left;
}

.workers-table th {
    background-color: var(--off-white-color);
    cursor: pointer;
    position: relative;
    padding-right: 25px;
}

.workers-table th:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.workers-table tr:nth-child(even){background-color: var(--off-white-color);}

.workers-table tr:hover {background-color: var(--accent-color);}

.view-details, .edit, .delete {
    margin-right: 10px;
    text-decoration: none;
    color: var(--off-white-color);
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--accent-color-2);
}
