.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  text-align: left;
}

.login-form label {
  display: block;
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.login-button {
  background-color: var(--accent-color); /* Use your defined variables */
  color: var(--dark-navy-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-family: 'Quicksand', sans-serif;
}

.login-button:hover {
  opacity: 0.8;
}